@import styles/theme

.App
    background: radial-gradient(circle, rgba(24,42,62,1) 0%, rgba(1,1,3,1) 100%)

.text-bright-blue
    color: #008ffc

a
    text-decoration: none
    transition: 0.3s ease
    opacity: 0.8
    &:hover
        opacity: 1

.h-100vh
    height: 100vh