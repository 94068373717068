.shadow-overlay
    background-color: rgba($fg-on-light-bg,0.5)

.bg-none
    background: rgba(0,0,0,0)

.bg-primary
    background: $bg-primary
    color: rgba($fg-on-dark-bg,1)

.bg-light-matte
    background: rgba($fg-on-dark-bg,0.08) !important

.bg-dark-matte
    background: rgba($fg-on-light-bg, 0.8) !important

.bg-gradient-black-right
    background: linear-gradient(90deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.8) 70%, rgba(0,0,0,1) 100%) !important

.bg-gradient-black-left
    background: linear-gradient(270deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.8) 70%, rgba(0,0,0,1) 100%) !important