@import url('https://fonts.googleapis.com/css2?family=Paytone+One&family=Raleway&display=swap')

.prime-font, h1, h2, h3
    font-family: 'Paytone One', sans-serif

.secondary-font, a, p, h4, h5, button, li
    font-family: 'Raleway', sans-serif
p
    margin-bottom: 0

.text-secondary
    background: $bg-secondary
    background-clip: text
    color: transparent !important

.text-primary
    background: $bg-primary
    background-clip: text
    color: transparent !important

.underline-border-left::after
        content: ""
        width: 5%
        height: 3px
        background-color: rgba($fg-on-dark-bg ,1)
        left: 0
        position: relative
        display: block
        top: 10px
        @media screen and (max-width: $layout-breakpoint-medium)
            width: 25%
        
.text-overflow-ellipsis
        overflow: hidden
        text-overflow: ellipsis