.blink 
    animation: blink 2.5s linear
    opacity: 0.4
    
.infinite
    animation-iteration-count: infinite

@keyframes blink
    50%
        opacity: 0.2 
        